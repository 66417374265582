import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactGA from "react-ga4";
import ChatMessage from "./ChatMessage";
import { useDropzone } from "react-dropzone";
import Header from "./Header";
import Footer from "./Footer";
import Spinner from "./Spinner";
import {
  faRobot,
  faUser,
  faCopy,
  faUndo,
  faUpload,
  faPaperPlane,
  faMicrophone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorBoundary } from "react-error-boundary";
import { parse } from "stacktrace-parser";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import useSpeechRecognition from "../hooks/useSpeechRecognition";

const ChatApp = () => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatusMessage, setUploadStatusMessage] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [selectedOption, setSelectedOption] = useState("search");
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [localMessage, setLocalMessage] = useState([]);
  const [showToaster, setShowToaster] = useState(false);
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [error, setError] = useState(null);
  const [rateLimitError, setRateLimitError] = useState(null); // Added for rate limit error

  const { isListening, transcript, error: speechError, setIsListening } = useSpeechRecognition();

  useEffect(() => {
    if (transcript) {
      setInputText(transcript);
    }
  }, [transcript]);

  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize("G-NFKD8XWG73");
    ReactGA.send("pageview");
  }, []);

  const handleUserMessage = (message) => {
    ReactGA.event({
      category: "User",
      action: "Sent a Message",
      label: message,
    });
  };

  const handleChange = (e) => {
    const message = e.target.value;
    setInputText(message);
    handleUserMessage(message);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value === "search") {
      setFiles([]);
    }
  };

  const ErrorFallback = ({ error }) => {
    const parsedStack = StackTraceParser.parse(error.stack);
    logErrorToServer(error.message, parsedStack);
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
      </div>
    );
  };

  const logErrorToServer = async (message, stack) => {
    try {
      await axios.post("/api/log-error", { message, stack });
    } catch (err) {
      console.error("Failed to log error to server:", err);
    }
  };

  const handleFilesUpload = async () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    const xhr = new XMLHttpRequest();
    xhr.upload.onprogress = (progressEvent) => {
      if (progressEvent.lengthComputable) {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(progress);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        setUploadStatusMessage("Files uploaded successfully");
      } else {
        setUploadStatusMessage("File upload failed");
      }
    };

    xhr.onerror = () => {
      setUploadStatusMessage("File upload failed");
    };

    xhr.open("POST", "http://localhost:4000/uploadfiles");
    xhr.send(formData);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
    [files]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".pdf,.docx,.doc,.xls,.xlsx",
  });

  const handleReset = (event) => {
    if (event) event.preventDefault();
    setMessages([]);
    setInputText("");
  };

  const apiUrl = import.meta.env.VITE_APP_API_URL || "http://localhost:4000";

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setRateLimitError(null); // Reset rate limit error

    let endpoint = "/api/send-message";

    if (selectedOption === "search") {
      endpoint = "/api/send-message-search";
    } else if (selectedOption === "llama") {
      endpoint = "/api/send-message-awsbedrock";
    } else if (selectedOption === "claude") {
      endpoint = "/api/send-message-awsclaude";
    } else if (selectedOption === "gemini") {
      endpoint = "/api/send-message-gemini";
    }

    const fetchUrl = `${apiUrl}${endpoint}`;

    try {
      const payload = { message: inputText };

      const response = await fetch(fetchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 429) {
        throw new Error("Rate limit exceeded");
      }

      if (!response.ok) {
        throw new Error(`Error sending message: ${response.statusText}`);
      }

      let assistantMessage;
      let data;

      try {
        data = await response.json();
      } catch (error) {
        data = await response.text();
      }

      if (typeof data === "string") {
        assistantMessage = data;
      } else if (data.content) {
        // Handling the Claude response format
        assistantMessage = data.content[0].text || "No response from Claude.";
      } else if (data.response) {
        if (selectedOption === "llama") {
          assistantMessage = sanitizeText(data.response.generation);
        } else {
          try {
            const parsedResponse = JSON.parse(data.response);
            assistantMessage =
              parsedResponse.assistant || parsedResponse.message;
          } catch (parseError) {
            assistantMessage = data.response.assistant || data.response;
          }
        }
      } else {
        assistantMessage = "No response from assistant.";
      }

      setMessages([
        ...messages,
        { text: inputText, user: true },
        { text: assistantMessage, user: false },
      ]);

      setInputText("");
      setIsInputEmpty(true);
      setError(null);
    } catch (error) {
      console.error("Error:", error);
      if (error.message === "Rate limit exceeded") {
        setRateLimitError(
          "You have exceeded the maximum number of requests for today. Please try again tomorrow."
        );
      } else {
        setError("Failed to get response from the server.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const sanitizeText = (text) => {
    return text.replace(/`/g, "").replace(/[*_~]/g, "");
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div>
        <Header />
        <div className="inputWrapper">
          <div className="chat-messages">
            {messages.map((message, index) => (
              <ChatMessage
                key={index}
                message={message}
                isBot={!message.user}
              />
            ))}
            {isLoading && <Spinner />}
          </div>
          <form onSubmit={handleSubmit} style={{ marginBottom: "10px" }}>
            <div className="radio-container">
              <label htmlFor="searchOption">
                <input
                  type="radio"
                  id="searchOption"
                  name="option"
                  value="search"
                  checked={selectedOption === "search"}
                  onChange={handleOptionChange}
                />
                <span className="custom-radio"></span>
                GPT-4o
              </label>
              <label htmlFor="geminiOption">
                <input
                  type="radio"
                  id="geminiOption"
                  name="option"
                  value="gemini"
                  checked={selectedOption === "gemini"}
                  onChange={handleOptionChange}
                  onKeyDown={handleKeyDown}
                />
                <span className="custom-radio"></span>
                Google Gemini
              </label>
              <label htmlFor="llamaOption">
                <input
                  type="radio"
                  id="llamaOption"
                  name="option"
                  value="llama"
                  checked={selectedOption === "llama"}
                  onChange={handleOptionChange}
                  onKeyDown={handleKeyDown}
                />
                <span className="custom-radio"></span>
                Llama-3 by Meta
              </label>
              <label htmlFor="claudeOption">
                <input
                  type="radio"
                  id="claudeOption"
                  name="option"
                  value="claude"
                  checked={selectedOption === "claude"}
                  onChange={handleOptionChange}
                  onKeyDown={handleKeyDown}
                />
                <span className="custom-radio"></span>
                Claude by Anthropic
              </label>
            </div>

            <div className="chat-container">
              <textarea
                placeholder={`Ask something to ${
                  selectedOption === "search"
                    ? "GPT-4o"
                    : selectedOption === "gemini"
                    ? "Google Gemini"
                    : selectedOption === "claude"
                    ? "Claude by Anthropic"
                    : selectedOption === "llama"
                    ? "Llama-3"
                    : "Llama-3"
                }...`}
                className="inputPrompt"
                value={inputText}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                autoFocus
              />
              <button
                type="button"
                className="button"
                onClick={() => setIsListening((prev) => !prev)}
              >
                <FontAwesomeIcon icon={faMicrophone} />
                {isListening ? " Stop" : " Start"} Listening
              </button>
            </div>

            {error && (
              <p className="error" style={{ color: "red" }}>
                {error}
              </p>
            )}
            {rateLimitError && (
              <p className="rate-limit-error" style={{ color: "red" }}>
                {rateLimitError}
              </p>
            )}
            {speechError && (
              <p className="speech-error" style={{ color: "red" }}>
                {speechError}
              </p>
            )}

            <div>
              <button type="submit" className="button">
                Send <FontAwesomeIcon icon={faPaperPlane} />
              </button>
              <button
                type="button"
                className="button reset-button"
                onClick={handleReset}
              >
                Reset &nbsp; &nbsp;
                <FontAwesomeIcon icon={faUndo} />
              </button>
            </div>
          </form>
        </div>

        <Footer />
      </div>
    </ErrorBoundary>
  );
};

export default ChatApp;