import React from "react";

const Footer = () => {
  return (
    <footer className="chat-header">
      <p>
        Copyright{" "}
        <a target="_blank" href="https://linkedin.com/in/selvaonline">Selvakumar Murugesan </a>{" "}
        (www.selvaonline.com){" "}
      </p>
    </footer>
  );
};

export default Footer;
