import React from "react";

const Header = () => {
  return (
    <header className="chat-header">
   <img
        src="/logo.png"
        style={{ width: '50px', height: '50px' }}
        alt="my-docs.ai"
      />
      <h1>my-docs.ai</h1> <p>Ask anything to your faviorite AI Models</p>{" "}
    </header>
  );
};

export default Header;
