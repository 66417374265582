import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const ChatMessage = ({ message }) => {
  const isBot = !message.user;

  const renderMessageContent = () => {
    if (message.text) {
      return (
        <div>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {typeof message.text === "string"
              ? message.text
              : JSON.stringify(message.text)}
          </ReactMarkdown>
        </div>
      );
    } else if (Array.isArray(message)) {
      return (
        <ul>
          {message.map((item, index) => {
            if (typeof item === "object" && item != null) {
              return (
                <li key={index}>
                  {Object.entries(item).map(([key, value]) => (
                    <div key={key}>
                      <strong>{key}:</strong>{" "}
                      {typeof value === "string"
                        ? value
                        : JSON.stringify(value)}
                    </div>
                  ))}
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
      );
    } else {
      if (!message.queryResult) {
        return (
          <div>No records found at this time. Try a different search?</div>
        );
      } else {
        return null;
      }
    }
  };

  return (
    <div className={isBot ? "bot-message" : "user-message"}>
      {renderMessageContent()}
    </div>
  );
};

export default ChatMessage;
